<template>
  <v-container fluid>  
                <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
 
                <v-date-picker v-model="date" type="date" scrollable >
                    <v-btn text color="primary" @click="modal = false">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>

                   <v-btn text color="primary" @click="clear_period()">
                        Clear
                    </v-btn>
                    <v-btn text color="primary" @click="change_period(date)">
                        Modify
                    </v-btn>
                </v-date-picker>
            </v-dialog>
        <listitems
          :title="'Repair List'"
          :list="reparations"
          :showedit="false"
          :headers="headers"
          :master="true"
          :add="false"
          :del="false" 
          @open="open_reparation"
          @col_btn1_click="edit_reparation"
          :key="klist" 
          :laoding="loading"     
        >
        </listitems> 
 <waiter :showWait="waiter"> </waiter>
    <div>
      <reparationform
        :dialog="rep_dialog"
        :item="selitem" 
        :user_list="user_list"
        @close_reparation="close_reparation"
        @refresh_reparation="refresh_reparation"
        :key="kform"
        :reparation_List="reparation_List"
           :files_list="files_list"
        :failures="failures"
        :barriers="barriers"
      >
      </reparationform>
    </div>
  </v-container>
</template>
<script> 
import REPARATION_LIST from "../graphql/Reparation/REPARATIONS.gql";
import getElements_list from "../graphql/Reparation/DATA.gql";
import SaveDueDate from "../graphql/Reparation/SaveReparationDueDate.gql";
export default {
  components: { 
    reparationform: () => import("../components/RepairForm.vue"),
    listitems: () => import("../components/ListItems.vue"),
     waiter: () => import("../components/Widgets/Waiter.vue"),
  },
  data() {
    return {
      date:null,
      modal:false,
      reparations: [],
      user_list: [],
      selitem: {},
      details: [],
      wells: [],
      files_list: [],
      klist: 0,
      kform: 1000,
      rep_dialog: false,
      reparation_List:[],
      headers: [
        {
          value: "id",
          selected: false,
        },
        {
          value: "wellname",
          text: "Well",
          selected: true,
        },
        {
          text: "Repair Date",
          value: "date_reparation",
          selected: true,
        },
        {
          value: "nb",
          text: "Failures count",
          selected: true,
        },
        {
          value: "severity",
          text: "Integrity",
          selected: true,
          slot: "progress",
          color: "color",
        },
        {
          value: "duedate", 
          slot:"href",
          text: "Due Date",
          selected: true,
        },
        {
          value: "given_action", 
          text: "Action",
          selected: true,
        },
 
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          valeur: 0,
          sortable: false,
        },
      ],
      failures:[],
      barriers:[],
      loading:false,
        waiter:false,
    };
  },
  computed: {
    // Qselect() {
    //   return REPARATION_PAGES;
    // },
    // Qselectall() {
    //   return REPARATION_LIST;
    // },
  },

  created() {
    this.getdata();
   
  },
  methods: {
   async change_period(date){
        await this.$apollo
                    .query({
                        query: SaveDueDate,
                        variables: {
                            date: date,
                            id: this.selitem.well_id, 
                        },
                        fetchPolicy: "no-cache",
                    })
                    .then(() => { 
                        this.selitem.duedate=date;
                          this.klist++;
                    })
                    .catch(() => {});
                    this.modal=false;
     },
    async clear_period(){
      await  this.$apollo
                    .query({
                        query: SaveDueDate,
                        variables: {
                            date: null,
                            id: this.selitem.well_id, 
                        },
                        fetchPolicy: "no-cache",
                    })
                    .then(() => { 
                      this.getdata() ;
                    })
                    .catch(() => {});
                     this.modal=false;
     },
    async getdata() {
       this.loading=true;
      let r = await this.$requette(REPARATION_LIST);
      if (r) {
        this.reparations = r.data.reparation_list; 
        this.user_list = r.data.users;
        this.klist++;
      }
       this.loading=false;
    },
    refresh_reparation() {
      this.getdata();
    },
    close_reparation() {
      this.rep_dialog = false;
    },
    async open_reparation(item) {
       this.selitem = item;
      this.modal=true; 
    },
    async select_reparation(item) {
      this.selitem = item;
    },
    async edit_reparation(item) { 
      this.waiter=true;
    
      this.selitem = item;
      let r = await this.$apollo.query({
        query: getElements_list,
        variables: { Well_id: parseInt(item.well_id),id:item.id,table:"Rep" },
        fetchPolicy: "no-cache",
      });
      if (r) {
         this.reparation_List = r.data.getElements_list; 
         this.failures = r.data.getfailures_list; 
         this.barriers = r.data.wmf_list; 
         this.rep_dialog = true;
         this.files_list=r.data.get_files; 
         this.kform++;
      } 
  
      this.waiter=false;
    
    },
  },
};
</script>
